@import "../../node_modules/reset-css/reset.css";
@import "variables";
@import "../../node_modules/spectre.css/src/spectre.scss";

body {
  background: #140819 url(https://s3.wasabisys.com/blocsonic/remix-kits/2020-dankesun/bg.gif) center no-repeat fixed;
  background-size: cover;
  text-align: center;
  color: #CCC;
  display: flex;
  min-height: 100vh;

  h1 {
    font-size: 3rem;
    margin: 6rem 0 3rem 0;
  }

  h2 {
    margin-top: 3rem;
  }

  h1, h2, h3 {
    font-family: 'Alegreya SC', serif; font-weight: 400; font-style: italic;
  }

  h3, h4, h5, h6 {
    margin-top: 1.5rem;
  }

  p {
    text-align: left;
    line-height: 1.5;
    font-weight: 400;
  }

  em {
    font-style: oblique;
  }

  & > div {
    margin: 0 auto;
    align-self: center;
    flex: auto;
    max-width: 38.4rem;
    padding: 0 1rem;
  }

  footer {
    margin: 4rem auto 6rem auto;
    max-width: 25rem;
  }

  .lead {
    font-size: 1.15rem;
    line-height: 1.4;
  }

  .btn-download {
    margin-top: 1rem;
    background-color: #30c0dc;
    border-color: #30c0dc;
    color: #fff;


    &:hover, &:focus, &:active {
      background-color: darken(#30c0dc, 17%);
      border-color: darken(#30c0dc, 17%);
    }
  }
}

.cta-container {
  margin-top: 3rem;
}

.cta {
  display: block;
  text-align: center;
  padding: 1rem;
  background-color: #0f7ffe;
  transition: background-color 150ms ease;
  
  &, &:hover, &:focus, &:visited {
      color: #fff;
      text-decoration: none;
  }

  &:hover, &:focus {
      background-color: darken(#0f7ffe, 7%);
  }
}